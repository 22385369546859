import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    width: 240,
    fixed: "left",
    ellipsis: true,
  },
  {
    title: T("统计日期"),
    dataIndex: "stats_date",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },

  {
    title: T("货品条码"),
    dataIndex: "material_barcode",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("容器编号"),
    dataIndex: "pallet_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("状态"),
    dataIndex: "status_display",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("库存数量"),
    dataIndex: "quantity",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("库位编号"),
    dataIndex: "location_number",
    width: 120,
    ellipsis: true,
  },
];
